import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import isEqual from 'lodash/isEqual'
import sortBy from 'lodash/sortBy'
import cloneDeep from 'lodash/cloneDeep'
import pluralize from 'pluralize'
import { isMobilePhone } from 'validator'

import { getParamValue, getQueryValue } from 'selectors/pageStateSelectors'
import { STATE_KEY } from 'reducers/appStatusReducers'
import { getEntity, findAllEntities } from 'selectors/entitySelectors'
import {
  getCurrentQuoteValue,
  getQuoteIds,
  isQuoteDirty,
} from 'selectors/quoteSelectors'

import { getIsLoggedIn } from 'selectors/authSelectors'
import { getIsDealerBrowser } from 'selectors/authSelectors'

import { WHEN_INCLUDE, tagDiff, isCustomerKnown } from 'utils'
import { isTradeValid } from 'utils/trade'

export const TIMEOUT_THRESHOLD = 5000000
export const HIDDEN_PREFIX = 'x'
export const REQUEST_SEP = '_'
export const WIGGLE_PERCENT = 1

export const USER_ROLES = {
  customer: 'Promoted to Full Customer (Gone)',
  ninja: 'Ninja (Sales/BDC Agent)',
  samurai: 'Samurai (Sales Manager)',
  sensei: 'Admin',
}

export const THIRD_PARTY_INTEGRATIONS = {
  lesaVdp: 'lesaVdp',
}

export const CONFIG_ATTRS = ['condition', 'year', 'make', 'model', 'trim']

export const getStatus = state => get(state, `[${STATE_KEY}].status`, ''),
  getMessage = state => get(state, `[${STATE_KEY}].message`, ''),
  getAppVersion = state => get(state, `[${STATE_KEY}].appVersion`, ''),
  getBrowser = state => {
    return {
      ...get(state, `[${STATE_KEY}].browser`, ''),
      referrer: window.document.referrer,
    }
  },
  getSessionId = state => get(state, `[${STATE_KEY}].sessionId`, null),
  getFingerPrint = state => get(state, `[${STATE_KEY}].fingerprint`, null),
  getBrowserName = state => get(state, `[${STATE_KEY}].browser.name`, ''),
  isBrowserIos = state =>
    get(state, `[${STATE_KEY}].browser.name`, '') === 'ios',
  isAndroid = state =>
    get(state, `[${STATE_KEY}].browser.os`, '') === 'Android OS',
  isMobile = state => isBrowserIos(state) || isAndroid(state),
  isBot = state => !!get(state, `[${STATE_KEY}].browser.bot`, false),
  isFramedIn = () => {
    try {
      return window.self !== window.top
    } catch (e) {
      return true
    }
  },
  isPaymentIQView = isFramedIn,
  getTheme = state =>
    get(state, `[${STATE_KEY}].demoInfo.theme`, '') ||
    get(state, `[${STATE_KEY}].rooftopInfo.theme`, ''),
  shouldDisplayEmoji = state =>
    get(state, `[${STATE_KEY}].demoInfo.displayEmoji`, '') ||
    get(state, `[${STATE_KEY}].rooftopInfo.displayEmoji`, ''),
  getRooftopName = state =>
    get(state, `[${STATE_KEY}].demoInfo.name`, '') ||
    get(state, `[${STATE_KEY}].rooftopInfo.name`, ''),
  getRooftopZipcode = state =>
    get(state, `[${STATE_KEY}].rooftopInfo.address.postalCode`, ''),
  getRooftopDefaultCustomerZip = state =>
    get(
      getRooftopConfigGroup(state, 'quote'),
      'scanOptions[0].customerZipCode',
      '',
    ) || get(getRooftopInfo(state), 'address.postalCode'),
  getRooftopId = state => getParamValue(state, 'dealerId'),
  getRooftopInfo = state => get(state, `[${STATE_KEY}].rooftopInfo`, {}),
  getRooftopUuid = state => get(getRooftopInfo(state), 'uuid'),
  getRooftopWebsite = state =>
    get(
      state,
      `[${STATE_KEY}].rooftopInfo.websiteDomain`,
      'www.frikintech.com',
    ),
  getRooftopAllowanceDomain = state =>
    get(
      state,
      `[${STATE_KEY}].rooftopInfo.allowanceWebsiteDomain`,
      'www.frikintech.com',
    ),
  getRooftopAllowanceRange = state =>
    get(
      state,
      `[${STATE_KEY}].rooftopInfo.allowanceRange`,
      '',
    ),
  getRooftopAllowanceZipcode = state =>
    get(
      state,
      `[${STATE_KEY}].rooftopInfo.allowancePostalCode`,
      '',
    ),
  getTPDealerID = state =>
    get(state, `[${STATE_KEY}].rooftopInfo.config.tradepending`, {}),
  getFBPixelId = state =>
    get(state, `[${STATE_KEY}].rooftopInfo.config.facebookTrackingPixelId`),
  getGoogleTagManagerId = state =>
    get(state, `[${STATE_KEY}].rooftopInfo.config.googleTagManagerId`),
  getGA4Id = state => get(state, `[${STATE_KEY}].rooftopInfo.config.ga4Id`),
  getRooftopConfigGroup = (state, group, def) =>
    get(state, `[${STATE_KEY}].rooftopInfo.config.${group}`, def),
  isLoadingConfigGroup = (state, group, def) =>
    isEmpty(get(state, `[${STATE_KEY}].rooftopInfo.config.${group}`, def)),
  getActiveRequests = state => get(state, `[${STATE_KEY}].outstandingRequests`),
  getLastRetry = state => get(state, `[${STATE_KEY}].lastRetry`),
  getRequest = (state, requestKey) =>
    get(state, `[${STATE_KEY}].outstandingRequests[${requestKey}]`, ''),
  isActiveRequest = (state, requestKey) => {
    let reqTimestamp = getRequest(state, requestKey),
      now = new Date().getTime()

    return !!reqTimestamp && now - reqTimestamp < TIMEOUT_THRESHOLD
  },
  getRooftopUrl = (state, addendum) => {
    let rooftopId = getRooftopId(state)

    return [window.location.origin, rooftopId, addendum]
      .filter(x => x)
      .join('/')
  },
  anyActiveRequests = state => {
    let requests = get(state, `[${STATE_KEY}].outstandingRequests`, {})

    return (
      Object.keys(requests)
        .map(r => r.split(REQUEST_SEP)[0] !== HIDDEN_PREFIX)
        .filter(r => r).length > 0
    )
  },
  isRoofTopLoading = state => isEmpty(getRooftopInfo(state)),
  getBanks = state => get(state, `[${STATE_KEY}].banks`, []),
  getBankByCode = (state, code) =>
    get(
      getBanks(state).find(
        b =>
          Object.keys(b.codes).includes && Object.keys(b.codes).includes(code),
      ),
      'name',
      code,
    ),
  isPreparedQuote = (state, qId) => {
    let preparedQId = get(state, `[${STATE_KEY}].shortCodeQuoteId`)

    return !qId || preparedQId === qId
  },
  getHours = state => get(getRooftopInfo(state), 'hours'),
  getWhyBuy = state => get(getRooftopInfo(state), 'whybuy'),
  getAddress = state => get(getRooftopInfo(state), 'address', {}),
  getPhoneNumber = (state, type = 'main') =>
    get(
      get(getRooftopInfo(state), 'phone', []).find(p => p.type === type),
      'number',
    ),
  isLoadingInteractions = state =>
    get(state, `[${STATE_KEY}].loadingQuoteInteractions`, false),
  getErrorsFor = (state, errorsKey) =>
    get(state, `[${STATE_KEY}].errors.${errorsKey}`, {}),
  getUser = (state, id) => getEntity(state, id),
  getUsersList = state => {
    return findAllEntities(state, e => get(e, '_entityType') === 'user')
  },
  getFindingUser = state =>
    get(state, `[${STATE_KEY}].findingUser.finding`, false),
  getFoundUser = state => get(state, `[${STATE_KEY}].findingUser.user`, null),
  getAvailableConfigs = state => {
    return get(state, `${STATE_KEY}.availableConfigs`, {})
  },
  getDefaultQuoteConfig = () => {},
  getCurrentConfigIdentifiers = (state, configId) => {
    let config = get(getAvailableConfigs(state), configId, {})

    let ret = CONFIG_ATTRS.reduce(
      (ret, k) => ({ ...ret, [k]: config[k] || null }),
      {},
    )

    return ret
  },
  makeChooserLabel = config => {
    let { condition, year, make, model, trim } = config || {},
      label = `${[condition, year, make, model, trim].filter(x => x).join(' ')}`

    label = !make && !model ? `${label} Vehicles` : label
    label = !make || !model ? `All ${label}` : label

    return pluralize(label)
  },
  getConfigVehicleValues = (state, key) =>
    get(state, `[${STATE_KEY}].${key}`, []),
  getConfigChooserValues = (state, category) => {
    let availableConfigs = getConfigsForCategory(state, category)

    return sortBy(Object.keys(availableConfigs), k =>
      makeChooserLabel(availableConfigs[k]),
    ).reduce(
      (ret, k) => ({ ...ret, [k]: makeChooserLabel(availableConfigs[k], k) }),
      {},
    )
  },
  getMatchingExisitngConfig = (state, identifiers) => {
    let existingConfigs = getAvailableConfigs(state)

    return Object.keys(existingConfigs).some(k =>
      isEqual(existingConfigs[k], identifiers),
    )
  },
  getGlobalConfigIdFromAvailable = (state, category) => {
    let available = getConfigsForCategory(state, category)

    return Object.keys(available).find(k =>
      CONFIG_ATTRS.every(a => {
        let v = get(available, `${k}.${a}`)

        return isNull(v)
      }),
    )
  },
  getActiveConfig = state => ({
    ...get(state, `[${STATE_KEY}].activeConfig`, {}),
  }),
  getIsGettingConfig = state =>
    get(state, `[${STATE_KEY}].isGettingConfig`, {}),
  getConfigLabel = (state, id) => {
    return makeChooserLabel(get(state, `[${STATE_KEY}].availableConfigs.${id}`))
  },
  getConfig = (state, id) =>
    get(state, `[${STATE_KEY}].availableConfigs.${id}.config`, null),
  getFullConfig = (state, id) =>
    get(state, `[${STATE_KEY}].availableConfigs.${id}`, null),
  getConfigsForCategory = (state, category) => {
    let configs = getAvailableConfigs(state)

    return Object.keys(configs).reduce((ret, k) => {
      return configs[k].category === category
        ? { ...ret, [k]: configs[k] }
        : ret
    }, {})
  },
  getIsKnownCustomer = state => {
    let customer = getCustomer(state),
      user = getIsLoggedIn(state),
      isUser = !isEmpty(user) || get(customer, '_entityType') === 'dealer' // is logged in or ghosted

    return isUser || isCustomerKnown(customer)
  },
  getIsKnownCustomerFromState = getIsKnownCustomer,
  getTaggedCustomerFromState = state =>
    cloneDeep(get(state, `[${STATE_KEY}].customer`, {})),
  getCustomer = state => getTaggedCustomerFromState(state),
  TAG_TRACK_LIST = [
    'location.taxRegionLabel',
    'location.zipCode',
    'creditScore',
    'firstName',
    'lastName',
    'email',
    'phone',
    'hasNoRebates',
    'hasNoTrade',
    'sellMyTrade',
    new RegExp(/selectedRebateTypes$/), // mscan categories mapped to name, selectedRebateCategories is used in quote contexts
    new RegExp(/trades\.[0-9]+\.year/),
    new RegExp(/trades\.[0-9]+\.make/),
    new RegExp(/trades\.[0-9]+\.model/),
    new RegExp(/trades\.[0-9]+\.trim/),
    new RegExp(/trades\.[0-9]+\.fullYmmt/),
    new RegExp(/trades\.[0-9]+\.payoff/),
    new RegExp(/trades\.[0-9]+\.confirmedPayment/),
    new RegExp(/trades\.[0-9]+\.allowance/),
    new RegExp(/trades\.[0-9]+\.mileage/),
    new RegExp(/trades\.[0-9]+\.isLease/),
    new RegExp(/trades\.[0-9]+\.residual/),
    new RegExp(/trades\.[0-9]+\.id/),
    new RegExp(/trades\.[0-9]+\.confirmed/),
    new RegExp(/trades\.[0-9]+\.tradePending.report.tradein.low/),
    new RegExp(/trades\.[0-9]+\.tradePending.report.tradein.high/),
    new RegExp(/trades\.[0-9]+\.tradePending.report.tradein.target/),
  ],
  matchPath = (matchList = []) => ({ to } = {}, path = '') => {
    return (
      isEmpty(matchList) ||
      (!isNull(to) && matchList.find(tl => path.match(tl)))
    )
  },
  getMeaningfulDiff = tagDiff(matchPath(TAG_TRACK_LIST)),
  getCreditAppLlink = state =>
    get(getRooftopInfo(state), 'config.quote.displayOptions.creditAppLink'),
  getDrLink = state =>
    get(getRooftopInfo(state), 'config.quote.displayOptions.drLink'),
  getEnableDealerPolicyLink = state =>
    get(
      getRooftopInfo(state),
      'config.quote.displayOptions.enableDealerPolicyLink',
    ),
  getAccessoriesLink = state =>
    get(getRooftopInfo(state), 'config.quote.displayOptions.accessoriesLink'),
  getShowHomeDelivery = state =>
    get(getRooftopInfo(state), 'config.quote.displayOptions.homeDelivery'),
  getRooftopSalesPhone = state => {
    let phone = get(
      getRooftopInfo(state),
      'config.quote.displayOptions.salesPhone',
    )

    return isMobilePhone(phone || '', 'en-US') ? phone : null
  },
  getPaymentMods = (state, ignoreKnown) => {
    let { paymentRangePercent = 0, paymentRoundTo = 0 } =
      !getIsKnownCustomerFromState(state) || ignoreKnown
        ? get(getRooftopInfo(state), 'config.quote.scanOptions[0]', {})
        : {}

    return {
      paymentRangePercent: parseFloat(paymentRangePercent || 0),
      paymentRoundTo: parseInt(paymentRoundTo || 0, 10),
    }
  },
  getShouldIncludeSetting = (state, key) => {
    let isLoggedIn = !isEmpty(getIsLoggedIn(state)),
      isKnown = getIsKnownCustomerFromState(state),
      includeVal = get(
        getRooftopInfo(state),
        `config.quote.scanOptions[0].${key}`,
      ),
      ret =
        !isEmpty(isLoggedIn) ||
        includeVal === WHEN_INCLUDE.ALWAYS ||
        (includeVal === WHEN_INCLUDE.AUTO && isKnown)

    return ret
  },
  getShouldIncludeTaxesSetting = state => {
    return (
      getCurrentQuoteValue(state, 'includeTaxes') ||
      getShouldIncludeSetting(state, 'includeTaxes')
    )
  },
  getShouldIncludeFeesSetting = state => {
    return (
      getCurrentQuoteValue(state, 'includeFees') ||
      getShouldIncludeSetting(state, 'includeFees')
    )
  },
  getHidePriceToUnknownSetting = state => {
    return get(
      getRooftopInfo(state),
      'config.quote.scanOptions[0].hidePriceForUnknown',
    )
  },
  getDefaultSort = state => {
    return get(
      getRooftopInfo(state),
      'config.quote.displayOptions.defaultSort',
      'lease.payment.low',
    )
  },
  getShowAndTellList = state => {
    return get(state, `[${STATE_KEY}].rooftopInfo.showAndTell`, '')
  },
  getHasDraiver = state => {
    return get(state, `[${STATE_KEY}].rooftopInfo.hasDraiver`, false)
  },
  getSocketHealth = state => {
    return get(state, `[${STATE_KEY}].socketHealth`, '')
  },
  getSqueezeCount = state => {
    return get(state, `[${STATE_KEY}].squeezeCount`, '')
  },
  getAbeChatPosition = state => {
    return get(state, `[${STATE_KEY}].abeChatPosition`, '')
  },
  getScripts = state => {
    return get(state, `[${STATE_KEY}].rooftopInfo.scripts`, [])
  },
  getRebateDetails = (state, rId) => {
    return get(state, `[${STATE_KEY}].rebateDetails.${rId}`)
  },
  getQuoteDefaultValues = (state, key) => {
    return get(getRooftopInfo(state), `config.quote.${key}`, []).reduce(
      (ret, q) => [...ret, q.value.toString()],
      [],
    )
  },
  getQuoteDefaultValue = (state, key) => {
    let defaultOption = get(
      getRooftopInfo(state),
      `config.quote.${key}`,
      [],
    ).find(q => q.default)
    return get(defaultOption, 'value')
  },
  getFeature = (state, flag) => {
    return (
      get(getRooftopInfo(state), 'flags', []).some(
        f => f.code === flag && f.enabled,
      ) || false
    )
  },
  getThirdPartyIntegration = (state, key) =>
    get(state, `[${STATE_KEY}].rooftopInfo.thirdPartyIntegrations.${key}`, {
      enabled: false,
    }),
  getHasLesaVDP = state => {
    return get(
      getThirdPartyIntegration(state, THIRD_PARTY_INTEGRATIONS.lesaVdp),
      'enabled',
      false,
    )
  },
  getUsePaymentDifferenceFooter = state => getFeature(state, 'illumiquote/use-payment-difference-footer'),
  getUseSqueezeJacketReplacement = state => getFeature(state, 'illumiquote/use-squeeze-jacket-replacement'),
  getUseHouseBank = state => getFeature(state, 'payments/use-house-bank'),
  getHasMdrive = state => getFeature(state, 'marketscan/mdrive'),
  getHasSqueezeJacket = getHasMdrive,
  getHasUseFtcCompliantDisplay = state => getFeature(state, 'illumiquote/use-ftc-compliant-display'),
  getSqueezeJacketEnabled = state => {
    return get(
      getRooftopInfo(state),
      `config.quote.scanOptions[0].enableSqueezeJacket`,
      false,
    )
  },
  getLibertyMutualLinkEnabled = state =>
    getFeature(state, 'insurance/libertymutual'),
  getPaymentSearchWiggle = state =>
    get(
      getRooftopInfo(state),
      `config.quote.scanOptions[0].paymentSearchWiggle`,
      WIGGLE_PERCENT,
    ) / 100,
  getLocationLoading = state => get(state, `[${STATE_KEY}].gettingLocation`),
  getUserLocation = state => {
    let customer = getTaggedCustomerFromState(state),
      userLocation = get(customer, 'location', {
        zipCode: customer.zipCode,
      })

    return userLocation
  },
  getCustomerVerifications = state => {
    let customer = getCustomer(state) || {},
      isKnown = getIsKnownCustomer(state),
      gettingLocation = getLocationLoading(state),
      creditScore = get(customer, 'creditScore'),
      { trades = [], selectedRebateCategories = [] } = customer,
      rebateCats = selectedRebateCategories.map(c => c.Name),
      { location } = customer,
      locationVerified =
        !isEmpty(get(location, 'zipCode')) ||
        !isEmpty(get(location, 'taxRegionId')),
      customerLocationVerified =
        !isEmpty(get(customer, 'zipCode')) ||
        !isEmpty(get(customer, 'taxRegionId'))

    let trade =
        customer.hasNoTrade ||
        (trades.length && trades.every(t => isTradeValid(t))),
      locationV =
        !gettingLocation && (locationVerified || customerLocationVerified),
      rebates = !!(customer.hasNoRebates || rebateCats.length),
      credit = !!creditScore || creditScore === 0

    let ret = {
      contact: isKnown,
      trades: !!trade,
      location: locationV,
      rebates,
      credit,
    }

    ret.all = Object.keys(ret).every(k => ret[k])

    return ret
  },
  getProfileOk = state => {
    let isLoggedIn = getIsLoggedIn(state),
        isDealer = getIsDealerBrowser(state),
        squeezeJacketEnabled = getSqueezeJacketEnabled(state),
        squeezeJacketReplacementEnabled = getUseSqueezeJacketReplacement(state)


    return !isLoggedIn && !isDealer && (squeezeJacketEnabled || squeezeJacketReplacementEnabled)
  },

  getEnableSimulator = state => {
    return get(getRooftopInfo(state), 'enableSimulator')
  },
  isOnVDP = state => {
    let { quote, vehicle } = getQuoteIds(state)

    return quote && vehicle
  },
  shouldShowDistractor = state => {
    return get(state, `[${STATE_KEY}].showDistractor`, false)
  },
  areProgramsDirty = (state, vId, qId) => {
    return (
      isQuoteDirty(state, vId, qId) ||
      get(state, `[${STATE_KEY}].programsAreDirty`, false)
    )
  },
  isGettingPrograms = state => {
    return (
      get(state, `[${STATE_KEY}].gettingPrograms`, false) ||
      getCurrentQuoteValue(state, 'gettingPrograms', false) ||
      getCurrentQuoteValue(state, 'isLoading', false)
    )
  },
  isSqueezeJacketOpen = state =>
    getQueryValue(state, 'reaction') && getQueryValue(state, 'step'),
  getBuildTarget = () => process.env.REACT_APP_BUILD_TARGET,
  isHelix = () => getBuildTarget() === 'helix'
